import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(queryAbout)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='Software 💻 Craftsman 👨 | Works for Sifars 🏢 | Mostly open source technologies 🫂'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            {/* TODO: add image that is wide enough
            <Img image={image} />
            */}
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  I started by hacking android games using rooted android, during my Electrical diploma.
                  Started customizing android ROM and came across some web apps that allowed creating boot animation.
                  Started learning about animation but somehow started learning about web development to earn money and fame.
                </Text>
                <Text variant='p'>
                  Started using scripts available online, without a complete idea of the basic concepts of programming.
                  Failed to build anything meaningful, started to search for programming languages but ended up with the Laravel framework.
                  Learned about web development, database design, setting up servers, and a little bit about AWS cloud while trying to build an application.
                </Text>
                <Text variant='p'>
                  Joined Computer Science for B.Tech. at nearby college but lost hope in a degree once attended a few lectures.
                  Resumed project with some addition and changes like the addition of React, migrating from MySQL to PostgreSQL, etc.
                  The only positive points about college are the introduction to C++ and Stoicism, both helped in hard times that came later.
                  Due to a lack of resources and time during this period I started appreciating i3wm, SQLite, and test-driven development.
                </Text>
                <Text variant='p'>
                  After rejection from employers because of failure in B.Tech., from parents to relocation and some other issues I got my first job at Sifars.
                  Being the only developer and owner of a project that I worked on for about five years, I developed certain characteristics like keenness to learn and flexibility to settle into different roles.
                  But as I strived for perfection with the urge to fix everything and anger against mistakes resulted in sleep deprivation, so I ended up with Epilepsy and a few other things or we can say I kind of faced burnout.
                </Text>
                <Text variant='p'>
                  While most of the time I am proud to be right about making decisions for myself and predictions to friends but there are a few times when I am proud about accepting that I was wrong.
                  While I loved PostgreSQL, ECMAScript, and React from the very first day that I learned about but few things that Munish presented to me include Docker(containers) and TypeScript(something I despised because of its Microsoft roots).
                  During my (on paper) professional journey I have worked in different roles developing frontend and backend applications, administering servers and designing databases, and many things in between.
                </Text>
                  {/* TODO: add list of programs i love
                <Text variant='p'>
                  <ul>
                    <li>Cotidieque reformidans</li>
                    <li>Mucius sensibus</li>
                    <li>Sit primis iudicabit</li>
                  </ul>
                </Text>
                */}
                <Text variant='p'>
                  But one thing for sure is that so far it has been a great journey.
                </Text>
              </Section>
            </Box>
              {/* TODO: some roles that i played like as fronted dev and db designer
              with some comments about like what i love about it or favority resources
            <Box sx={styles.column}>
              <Section title='Art Directing'>
                <Card variant='paper'>
                  During the brainstorming process, art directors, co-workers,
                  and clients are engaged in imagining what the finished piece
                  or scene might look like.
                </Card>
              </Section>
              <Divider />
              <Section title='Digital Marketing'>
                <Card variant='paper'>
                  Digital marketing channels are systems based on the Internet
                  that can create, accelerate, and transmit product value from
                  producer to a consumer terminal, through digital networks
                </Card>
              </Section>
              <Divider />
              <Section title='Creative Designing'>
                <Card variant='paper'>
                  A core responsibility of the designer's job is to present
                  information in a way that is both accessible and memorable.
                </Card>
              </Section>
            </Box>
            */}
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const queryAbout = graphql`
  query QueryAbout {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
